h1,
h2,
h3,
h4,
h5,
h6,
.hero-unit h1,
.btn-link {
    color: #42A2D6
}
#home-slogan h1 {
  color: #000000;
}
#home-slogan h1 .colored {
  color: #42A2D6;
}
a:hover,
a:active {
    color: #42A2D6;
}
.overlay-info a.overlay-ico.links,
.overlay-info a.overlay-ico.zoom,
#myCarousel .carousel-control,
#loading {
	background: #42A2D6;
}
#widget-logo,
#logo {
    background: transparent;
}
#logo:hover {
    background: #fff;
}
#primary-menu ul li a.current,
#toggle:hover {
    background: #42A2D6;
}
#primary-menu ul li a:hover {
    background: #42A2D6;
}
#primary-menu ul li:hover ul li:hover {
    background: #42A2D6;
}
#primary-menu ul li {
    border-bottom: 1px solid #42A2D6;
}
.social-media li a {
    background: #42A2D6;
}
.social-media li a:hover {
    color: #2572EB;
}
#s, #s2 {
    background-color: #42A2D6;
}
.nav-pills>.active>a,
.nav-pills>.active>a:hover,
.view-tenth .mask,
.jcarousel-prev-horizontal,
.jcarousel-next-horizontal {
    background-color: #42A2D6;
}
.flex-direction-nav li a,
.flex-caption {
    background-color: #42A2D6;
}
.flex-direction-nav li a:hover {
    background-color: #2572EB;
}
.featured-wrapper {
    background-color: #42A2D6;
    box-shadow: 0 20px 20px -3px #666;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: rgba(105, 27, 184, 0.8);
}
::selection {
    background-color: #42A2D6;
}
::-moz-selection {
    background-color: #42A2D6;
}
::-webkit-selection {
    background-color: #42A2D6;
}
mark,
.map-holder {
    background-color: #42A2D6;
    color: #ffffff;
}
ul.services li h3 {
    background: #42A2D6;
}
ul.services a:hover h3 {
    background: #2572EB;
    transition: all 300ms ease 0s;
}
ul.services li:hover h3>span {
    color: #2572EB;
    transition: all 300ms ease 0s;
}
.crumb {
    border-bottom: 2px solid #42A2D6;
}
.breadcrumb .active {
    color: #42A2D6;
}
hr.soften {
    height: 1px;
    background-image: -webkit-linear-gradient(left, rgba(105, 27, 184, 0), rgba(105, 27, 184, .1), rgba(105, 27, 184, 0));
    background-image: -moz-linear-gradient(left, rgba(105, 27, 184, 0), rgba(105, 27, 184, .1), rgba(105, 27, 184, 0));
    background-image: -ms-linear-gradient(left, rgba(105, 27, 184, 0), rgba(105, 27, 184, .1), rgba(105, 27, 184, 0));
    background-image: -o-linear-gradient(left, rgba(105, 27, 184, 0), rgba(105, 27, 184, .1), rgba(105, 27, 184, 0));
    border: 0;
}
#main article img.avatar {
    border-color: #42A2D6;
    border-style: solid;
    border-width: 1px;
}
#flickr_badge_wrapper img:hover {
    border-color: #2572EB;
}
.btn-color {
    background-color: #42A2D6;
    background-image: linear-gradient(to bottom, #42A2D6, #42A2D6);
    background-image: -webkit-linear-gradient(top, #42A2D6, #42A2D6);
    background-image: -o-linear-gradient(top, #42A2D6, #42A2D6);
    background-repeat: repeat-x;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.btn-color:hover,
.btn-color:active,
.btn-color.active,
.btn-color.disabled,
.btn-color[disabled] {
    background-image: linear-gradient(to bottom, #1B58B8, #1B58B8);
    background-image: -webkit-linear-gradient(top, #1B58B8, #1B58B8);
    background-image: -o-linear-gradient(top, #1B58B8, #1B58B8);
    background-color: #1B58B8;
    color: #FFFFFF;
}
ul.services li h3:hover>span,
ul.services_big li:hover>.icon-wrap {
    color: #2572EB;
}
#home-slogan {
    border-top: 1px solid #42A2D6;
}

.services_big div{
  border: 1px solid #42A2D6;
  color: #333;
}

.services_big div::before {
  background-color: #fff;
}

.services_big a:hover div {
  background-color: #a5d3eb;
  color: #333;
}

.services_big li h3 {
  background-color: #42A2D6;
  color: #fff;
}

.contact-box a {
  color: #42A2D6;
}

.contact-box a .icon-mobile-phone {
  color: #444444;
}

.schulart_profile .profil {
  border-left: 1px solid #42A2D6;
  border-right: 1px solid #42A2D6;
}
.schulart_profile .profil > div {
  border-bottom: 1px solid #42A2D6;
}

.schulart_profile .profil header {
  background-color: #42A2D6;
  color: #fff;
}

.schulart_profile .profil header h3 {
  color: #fff;
}

button.register {
  background-color: #42A2D6;
  color: #fff;
}

.bg-grey {
  background-color: #f0f0f0;
}

.bg-midgrey {
  background-color: #e6e6e6;
}

.bg-blue {
  background-color: #42A2D6;
}

.border-blue {
  border: 2px solid #42A2D6;
}
