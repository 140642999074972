@font-face {
    font-family: RotisSansSerif;
	font-weight: normal;
	font-style: normal;
    src: url(../fonts/RotisSansSerif.woff2) format('woff2'), url(../fonts/RotisSansSerif.woff) format('woff'), url(../fonts/RotisSansSerif.ttf) format('ttf'), url(../fonts/RotisSansSerif.svg) format('svg'), url(../fonts/RotisSansSerif.eot) format('eot');
}

@font-face {
    font-family: RotisSansSerif;
	font-weight: normal;
	font-style: italic;
    src: url(../fonts/RotisSansSerif-Italic.woff2) format('woff2'), url(../fonts/RotisSansSerif-Italic.woff) format('woff'), url(../fonts/RotisSansSerif-Italic.ttf) format('ttf'), url(../fonts/RotisSansSerif-Italic.svg) format('svg'), url(../fonts/RotisSansSerif-Italic.eot) format('eot');
}

@font-face {
    font-family: RotisSansSerif;
	font-weight: bold;
	font-style: normal;
    src: url(../fonts/RotisSansSerif-Bold.woff2) format('woff2'), url(../fonts/RotisSansSerif-Bold.woff) format('woff'), url(../fonts/RotisSansSerif-Bold.ttf) format('ttf'), url(../fonts/RotisSansSerif-Bold.svg) format('svg'), url(../fonts/RotisSansSerif-Bold.eot) format('eot');
}

@font-face {
    font-family: RotisSansSerif;
	font-weight: 900;
	font-style: normal;
    src: url(../fonts/RotisSansSerif-ExtraBold.woff2) format('woff2'), url(../fonts/RotisSansSerif-ExtraBold.woff) format('woff'), url(../fonts/RotisSansSerif-ExtraBold.ttf) format('ttf'), url(../fonts/RotisSansSerif-ExtraBold.svg) format('svg'), url(../fonts/RotisSansSerif-ExtraBold.eot) format('eot');
}

@font-face {
    font-family: RotisSansSerif;
	font-weight: 300;
	font-style: normal;
    src: url(../fonts/RotisSansSerif-Light.woff2) format('woff2'), url(../fonts/RotisSansSerif-Light.woff) format('woff'), url(../fonts/RotisSansSerif-Light.ttf) format('ttf'), url(../fonts/RotisSansSerif-Light.svg) format('svg'), url(../fonts/RotisSansSerif-Light.eot) format('eot');
}

@font-face {
    font-family: RotisSansSerif;
	font-weight: 300;
	font-style: italic;
    src: url(../fonts/RotisSansSerif-LightItalic.woff2) format('woff2'), url(../fonts/RotisSansSerif-LightItalic.woff) format('woff'), url(../fonts/RotisSansSerif-LightItalic.ttf) format('ttf'), url(../fonts/RotisSansSerif-LightItalic.svg) format('svg'), url(../fonts/RotisSansSerif-LightItalic.eot) format('eot');
}
