body {
    font-family: 'RotisSansSerif', sans-serif;
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    word-spacing: -0.026em;
    letter-spacing: 0.02em;
    line-height: 1.5em;
    color: #000;
    text-rendering: optimizelegibility;
}
a {
    color: inherit;
}
a:hover,
a:active,
a:focus {
    text-decoration: none;
    -moz-outline-style: none;
    outline: none;
}

#main a {
	color: #42A2D6;
}
#main a:hover,
#main a:active,
#main a:focus {
	text-decoration: underline;
}

#main .btn-color, #main .btn-color.active, #main .btn-color.disabled, #main .btn-color:active, #main .btn-color:hover, #main .btn-color[disabled] {
	color: white;
}

a img {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    transition: transform 0.3s ease-out 0s, visibility 0.1s linear 0.1s;
    -webkit-transition: transform 0.3s ease-out 0s, visibility 0.1s linear 0.1s;
}
a .restraint {
    overflow: hidden;
}
a:hover img {
    opacity: 1;
	-webkit-transform: scale(1.2); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
    -ms-transform: scale(1.2);
	transform: scale(1.2);
}
::selection {
    color: #f7f7f7;
}
::-webkit-selection {
    color: #f7f7f7;
}
::-moz-selection {
    color: #f7f7f7;
}
h1 {
  font-size: 3.5em;
}
blockquote p {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
}
blockquote small {
    line-height: 25px;
    font-size: 95%;
}
#loading {
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-height: 1000px;
    position: fixed;
    z-index: 8888;
    top: 0;
    left: 0;
    opacity: .8;
}
#loader {
    position: fixed;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    top: 30%;
    left: 45%;
    background: url(../img/loader.gif) no-repeat transparent;
    z-index: 9999;
}
.separator {
    background-color: #DDDDDD;
    height: 2px;
    margin: 10px 0 20px;
    padding: 0;
}
.center {
    text-align: center;
}
#logo {
    padding: 0 10px 5px;
    text-align: center;
}
#logo:hover {
    opacity: 1;
}
#page-title {
    padding: 10px 0px;
}
#outer-content {
    margin-bottom: 50px;
    min-height: 400px;
    margin-top: 40px;
}
#outer-content3 {
    margin-bottom: -20px;
    min-height: 400px;
    margin-top: 40px;
}
#main p.meta {
    color: #808080;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
#main article img {
    height: auto;
    max-width: 100%;
    overflow: hidden;
    width: 100%;
}
#main article {
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 3em;
    padding: 0 0 9px;
}
#main article .page-header h3 {
    margin-top: 3em;
}/*
#main article.span4 {
    max-height: 375px;
    overflow: hidden;
}
#main article.span6 {
    max-height: 375px;
    overflow: hidden;
}*/
#main article.span4 .page-header h3 {
    max-height: 25px;
    overflow: hidden;
}
#comments {
    line-height: 24px;
    margin-bottom: 18px;
}
ol.commentlist {
    list-style-type: none;
    margin-left: 0;
}
ol.commentlist ul.children {
    list-style-type: none;
}
.comment .vcard>div {
    margin-top: 8px;
    padding: 0 10px;
}
ol.commentlist time {
    color: #999999;
    font-size: 0.8em;
}
ol.commentlist div.comment-author {
    position: relative;
}
ol.commentlist a.comment-edit-link {
    color: #FFFFFF;
    padding-left: 5px;
    text-decoration: none;
}
ol.commentlist span.edit-comment {
    position: absolute;
    right: 0;
    top: 8px;
}
ol.commentlist li.bypostauthor {
    background-color: #DDDDDD;
}
ol.commentlist ul.children li {
    background-color: transparent;
}
ol.commentlist ul.children li ul.children li {
    background-color: transparent;
}
ol.commentlist ul.children li ul.children li ul.children li {
    background-color: #AAAAAA;
}
ol.commentlist article {
    border-bottom: medium none!important;
}
a.comment-reply-link {
    bottom: 10px;
    position: absolute;
    right: 0;
}
#comment-nav ul {
    list-style-type: none;
    margin: 0;
}
#comment-form-elements {
    list-style-type: none;
    margin: 0;
}
.comment .vcard .avatar {
    padding-right: 0;
}
#main article img.avatar {
    padding: 0;
    width: 100%;
    height: auto;
}
ol.commentlist li {
    background-color: transparent;
    padding-right: 10px;
}
ol.commentlist .comment-text {
    border-bottom: 1px dotted #65707B;
    padding-bottom: 10px;
}
#pings {
    margin-bottom: 9px;
}
ol.pinglist {
    list-style-type: none;
    margin: 0 0 9px;
}
#comment {
    min-height: 150px;
}
ol.commentlist #respond {
    padding: 5px 10px;
}
#commentform .form-actions {
    background-color: transparent;
    border-top: medium none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
}
#commentform label {
    font-size: 12px;
}
#commentform input,
textarea,
.uneditable-input {
    width: auto;
}
ol.commentlist img.avatar {
    border: 1px solid #91D100;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    float: left;
    margin: 0 12px 0 0;
    padding: 2px;
}
.widget {
    margin-bottom: 30px;
}
.widgettitle {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #DDDDDD;
}
.widget li {
    margin-bottom: 10px;
}
.widget ul#tab-content li {
    margin-bottom: 0px;
}
.widget .nav-stacked>li {
    float: left;
    margin-right: 5%;
    width: 40%;
}
.featured-posts .item {
    border-color: #DDDDDD;
    border-style: dashed;
    border-width: 0 0 1px;
    margin-bottom: 12px;
    margin-top: 5px;
    min-height: 60px;
    position: relative;
}
.featured-posts .item:last-child {
    border-bottom: 0 none;
    margin-bottom: 8px;
}
.featured-posts .item a.image-box:hover {
    opacity: 0.6;
}
.featured-posts img {
    border-color: #EEEEEE;
    border-style: solid;
    border-width: 3px;
    left: 0;
    position: absolute;
    top: 0;
    width: 70px;
    height: 50px;
    overflow: hidden;
}
.featured-posts .info {
    margin-left: 88px;
}
.featured-posts .info * {
    clear: none;
    margin: 0;
    padding: 0;
}
.featured-posts .info h4 {
    line-height: 100%;
    margin-bottom: 6px;
}
.featured-posts .info p {
    font-size: 13px;
    line-height: 120%;
    margin-bottom: 10px!important;
}
ul.portfolio {
    margin-left: 0;
}
ul.portfolio li {
    margin-bottom: 30px;
}
.filterable {
    position: relative;
    margin-top: 20px;
}
.filterable .filterable {
    margin-top: -20px;
}
.filterable-grid {
    margin: 0px;
}
.filterable-grid li {
    list-style: none;
    float: left;
    margin-bottom: 25px;
    text-shadow: none;
    overflow: hidden;
    box-shadow: 0 10px 10px -3px #222;
}
.filterable-grid li .view {
    margin-bottom: 0;
}
.inner-filters a:hover {
    color: #d8450b;
    text-decoration: none;
}
.inner-filters li a {
    color: #222;
}
.inner-filters {
    margin: 10px 10px 20px 0px;
}
.inner-filters li {
    list-style: none;
    float: left;
    padding: 0 10px 0 0;
    text-transform: uppercase;
}
.inner-filters .last:after {
    content: "";
}
.inner-filters li:after {
    content: "\00bb";
    margin-left: 10px;
    color: #999;
    font-size: 18px;
}
.view {
    width: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
    background: #fff;
    box-shadow: 0 15px 15px -3px #222;
}
.view2 {
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
    background: #fff;
    box-shadow: 0 15px 15px -3px #222;
}
.view3 {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 60%;
    background: #fff;
    box-shadow: 0 15px 15px -3px #222;
}
.view3 img {
    position: absolute;
    top: 0;
    left: 0;
}
.view .mask,
.view .content {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.view2 .mask,
.view2.content {
    height: 400px;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.big.view .mask,
.big.view .content,
.big.view2 .mask,
.big.view2 .content {
    width: 100%;
    z-index: 999;
}
.big.view-tenth .mask {
    background-image: url(../img/layer.png);
    background-repeat: repeat;
}
.view img,
.view2 img {
    display: block;
    position: relative;
}
.view h2,
.view2 h2 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 20px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
    margin: 20px 0 0 0;
}
.view p,
.view2 p {
    font-size: 16px;
    position: relative;
    color: #fff;
    padding: 10px 20px 20px;
    text-align: center;
    margin-bottom: 0px;
}
.view a.info,
.view a.zoom,
.view2 a.info,
.view2 a.zoom {
    font-size: 13px;
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    background: #000;
    color: #fff;
    text-transform: uppercase;
    -webkit-box-shadow: 0 0 1px #000;
    -moz-box-shadow: 0 0 1px #000;
    box-shadow: 0 0 1px #000;
    position: relative;
    top: 45%;
}
.view a.info:hover,
.view a.zoom:hover,
.view2 a.info:hover,
.view2 a.zoom:hover {
    -webkit-box-shadow: 0 0 5px #000;
    -moz-box-shadow: 0 0 5px #000;
    box-shadow: 0 0 5px #000;
}
.view-tenth img {
    width: 100%;
    height: auto;
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -o-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition: all 0.7s ease-in-out;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}
.view-tenth .mask {
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    transition: all 0.5s linear;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
}
.view-tenth h2 {
    border-bottom: 1px dotted #fff;
    background: transparent;
    margin: 20px 40px 0px 40px;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    transition: all 0.5s linear;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
}
.view-tenth p {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    transition: all 0.5s linear;
}
.view-tenth a.info,
.view-tenth a.zoom {
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    transition: all 0.5s linear;
}
.view-tenth:hover img {
    -webkit-transform: scale(10);
    -moz-transform: scale(10);
    -o-transform: scale(10);
    -ms-transform: scale(10);
    transform: scale(10);
    opacity: 0;
}
.view-tenth:hover .mask {
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    filter: alpha(opacity=90);
}
.view-tenth:hover h2,
.view-tenth:hover p,
.view-tenth:hover a.info,
.view-tenth:hover a.zoom {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
}
.navbar-fixed-top {
    animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    -ms-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
}
#primary-menu {
    margin: 0;
    padding: 0;
    font-family: 'RotisSansSerif', sans-serif;
}
#primary-menu select {
    display: none;
}
#primary-menu select {
    border: 1px solid #555;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset;
    border-radius: 0 0 0 0;
    cursor: pointer;
    height: 100%;
    margin: 17px auto;
    padding: 15px 20px;
    width: 100%;
    font-size: 15px;
}
#primary-menu ul {
    margin: 20px 0 0;
    padding: 0;
    list-style-type: none;
    float: right;
    position: relative;
    box-sizing: border-box;
    z-index: 100;
}
#primary-menu ul li:hover {
    visibility: inherit;
}
#primary-menu ul li {
  box-sizing: border-box;
    position: relative;
    display: inline;
    float: left;
    padding-bottom: 10px;
}
#primary-menu ul li:last-child {
    background: none;
}
#primary-menu ul li a {
    text-shadow: none;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    color: inherit;
    padding: 20px 15px;
    display: block;
    float: left;
}
#primary-menu ul li a:hover,
#primary-menu ul li.sfHover a {
    background-color: #eee;
    color: #333;
}
#primary-menu ul li a.current {
    color: #fff;
}
#primary-menu ul ul {
    position: absolute;
    left: 0;
    top: 60px;
    min-width: 180px;
    background: #eee;
    color: #333;
    z-index: 9999;
}
#primary-menu ul ul ul {
  left: 100%;
  top: 0px;
}
#primary-menu ul ul li {
    width: 100%;
}
#primary-menu ul ul {
    padding: 0;
    margin: 0;
}
#primary-menu ul ul li {
    margin: 0;
    background: none;
    border-bottom: 1px solid #ddd;
    transition: all 300ms ease 0s;
    -moz-transition: all 300ms ease 0s;
    -ms-transition: all 300ms ease 0s;
    -webkit-transition: all 300ms ease 0s;
    -o-transition: all 300ms ease 0s;
}
#primary-menu ul ul li:last-child {
    border-bottom: none;
}
#primary-menu ul ul li a {
    font-size: 12px;
    padding: 15px 40px 5px 15px;
    width: 100%;
}
#primary-menu ul li:hover ul li a {
    color: #333;
    background: transparent;
}
#primary-menu ul li:hover ul li:hover {
    color: #fff;
}
#primary-menu ul li:hover ul li:hover>a {
    color: #fff;
    transition: all 300ms ease 0s;
    -moz-transition: all 300ms ease 0s;
    -ms-transition: all 300ms ease 0s;
    -webkit-transition: all 300ms ease 0s;
    -o-transition: all 300ms ease 0s;
}

@media (max-width: 1750px) {
  #primary-menu ul li:last-child ul {
    left: auto;
    right: 0px;
    top: 50px;
  }

  #primary-menu ul li:last-child ul li ul {
    left: auto;
    right: 100%;
    top: 0px;
  }
}


@media (max-width: 1650px) {
  #primary-menu ul li:nth-last-child(2) ul {
    left: auto;
    right: 0px;
    top: 50px;
  }

  #primary-menu ul li:nth-last-child(2) ul li ul {
    left: auto;
    right: 100%;
    top: 0px;
  }
}

.sitemap-menu {
    display: inline-block;
    margin: 0;
    padding: 10px 0;
}
.sitemap-menu li {
    float: left;
    list-style-type: none;
}
.sitemap-menu li:after {
    content: "|";
    font-size: 8px;
    padding-left: 10px;
    margin-right: 10px;
}
.sitemap-menu li:last-child:after {
    content: "";
    margin: 0;
    padding: 0;
}
.social-media ul {
    float: right;
}
.contact-box {
    display: inline-block;
    *margin-top: 7px;
    float: right;
    text-align: right;
    padding: 10px 0 0;
    font-size: 30px;
}
.contact-box .desc {
  font-size: 16px;
  white-space: nowrap;
}
.contact-box a {
  white-space: nowrap;
}
.social-media {
    margin: 0;
}
.social-media li {
    float: right;
    list-style-type: none;
}
.social-media li a {
    font-size: 16px;
    color: #fff;
    transition: all 1s ease 0s;
    width: 47px;
    height: 47px;
    display: inline-block;
    text-align: center;
    line-height: 47px;
}
.social-media li a:hover {
    transition: all 1s ease 0s;
}
.social-media li a:hover {
    background-color: #444444;
}
ul.contact {
    margin: 0;
}
ul.contact li {
    text-align: left;
    list-style-type: none;
    line-height: 2em;
}
.contact span {
    margin: 0px 0px 15px 0px;
    width: 50px;
}
.contact li {
    color: #fff;
    margin-bottom: 10px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 5px 5px -3px #222;
    -moz-box-shadow: 0 5px 5px -3px #222;
    box-shadow: 0 5px 5px -3px #222;
    zoom: 1;
    transition: all 3s ease 0s;
    -moz-transition: all 3s ease 0s;
    -ms-transition: all 3s ease 0s;
    -webkit-transition: all 3s ease 0s;
    -o-transition: all 3s ease 0s;
}
.contact li i {
    margin: 0px 10px 0px 0px;
    line-height: 40px;
    padding: 0px;
    width: 40px;
    text-align: center;
    height: 40px;
    display: inline-block;
    color: #ffffff;
    top: 0px;
    font-size: 24.5px;
    background: #222;
}
.searchform {
    display: inline-block;
    animation-duration: 2.5s;
    -moz-animation-duration: 2.5s;
    -webkit-animation-duration: 2.5s;
    -ms-animation-duration: 2.5s;
    -o-animation-duration: 2.5s;
}
#s, #s2 {
    background-image: url("../img/menu-search-icon.png");
    background-position: 96% 50%;
    background-repeat: no-repeat;
    border: none;
    border-radius: 0 0 0 0;
    float: right;
    font-size: 14px;
    height: 20px;
    margin-top: 8px;
    width: 150px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.featured-wrapper {
    padding: 25px 0;
    margin: 20px auto 0;
    background-image: url(../img/titelstreifen3.png);
	background-position: top center;
}
#home-slogan {
    background: #FFFFFF;
    margin-top: -46px;
    min-height: 282px;
    max-height: 346px;
    overflow: hidden;
    padding: 25px 15px 15px;
    position: relative;
    text-align: center;
    z-index: 50;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
    animation-duration: 3s;
    -moz-animation-duration: 3s;
    -webkit-animation-duration: 3s;
    -ms-animation-duration: 3s;
    -o-animation-duration: 3s;
}

#home-slogan hr.soften {
	height: 3px;
	margin: 25px 0;
}
.taglinedesc {
    font-size: 16px;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 20px;
    text-transform: uppercase;
}
#home-slogan h1 {
    font-size: 26px;
    font-weight: 300;
    line-height: 35px;
}
#home-slogan h1 .colored {
	display:inline-block;
    font-size: 40px;
    font-weight: 400;
	padding-bottom: 0.3em;
}
ul.services,
ul.services_big {
    text-align: center;
    display: table;
    margin: 0;
    text-align: center;
    width: 100%;
}
ul.services li {
    list-style-type: none;
    padding-bottom: 20px;
}
ul.services li h3 {
    padding: 0;
    color: #fff;
    text-align: left;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 5px 5px -3px #222;
    -moz-box-shadow: 0 5px 5px -3px #222;
    box-shadow: 0 5px 5px -3px #222;
    zoom: 1;
    transition: all 3s ease 0s;
    -moz-transition: all 3s ease 0s;
    -ms-transition: all 3s ease 0s;
    -webkit-transition: all 3s ease 0s;
    -o-transition: all 3s ease 0s;
}
ul.services li span {
    background: #222;
    padding: 10px;
    margin-right: 15px;
    text-shadow: 0 1px 1px #222;
    transition: all 1s ease 0s;
    transition: all 3s ease 0s;
    -moz-transition: all 3s ease 0s;
    -ms-transition: all 3s ease 0s;
    -webkit-transition: all 3s ease 0s;
    -o-transition: all 3s ease 0s;
}
ul.services li h3:hover>span {
    background: #000;
    transition: all 3s ease 0s;
    -moz-transition: all 3s ease 0s;
    -ms-transition: all 3s ease 0s;
    -webkit-transition: all 3s ease 0s;
    -o-transition: all 3s ease 0s;
}
ul.services li p {
    padding: 0 10px;
}
ul.services_big li {
  margin: 20px 0;
  border: medium none #fff;
}
ul.services_big li h3 {
    font-size: 20px;
}
ul.services_big li p {
  text-align: center;
    line-height: 25px;
}
ul.services_big .icon-wrap {
    margin: 0 auto;
}
ul.services_big li .icon-wrap {
    color: inherit;
    transition: all 1s ease 0s;
    transition: all 3s ease 0s;
    -moz-transition: all 3s ease 0s;
    -ms-transition: all 3s ease 0s;
    -webkit-transition: all 3s ease 0s;
    -o-transition: all 3s ease 0s;
}
ul.services_big li:hover>.icon-wrap {
    transition: all 3s ease 0s;
    -moz-transition: all 3s ease 0s;
    -ms-transition: all 3s ease 0s;
    -webkit-transition: all 3s ease 0s;
    -o-transition: all 3s ease 0s;
}
ul.services_big ul {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
  margin-top: 0.7em;
}
ul.services_big ul li {
  padding-left: 1em;
  text-indent: -0.7em;
}
ul.services_big ul li::before {
  content: "• ";
  color: #42A2D6; /* or whatever color you prefer */
}
.zusammenfassung ul li {
  line-height: 1.3em;
}
#outer-sponsor {
    margin: 40px auto 90px;
}
ul#sponsor {
    text-align: center;
    margin: 20px auto;
}
ul#sponsor li {
    background: inherit;
    cursor: pointer;
    display: inline-block;
    max-height: 80px;
    position: relative;
    margin: 0 10px;
    width: 200px;
}
.homepage_widgets_outer {
    background: #F1F1F1;
    padding-bottom: 40px;
}
.widget_comments {
    float: right;
    padding: 0;
    text-align: left;
    font-size: 14px;
}
.widget_testi {
    float: right;
    padding: 3px 0;
    text-align: left;
    font-size: 14px;
    font-style: italic;
}
.widget_testi:before {
    content: "-";
    margin-right: 10px;
}
.hour {
    background: url("../img/hour.png") no-repeat scroll left 85% transparent;
    float: right;
    line-height: 16px;
    padding: 2px 0 0 15px;
    font-size: 14px;
    margin-right: 10px;
}
.homepage_widgets_divider {
    background: url("../img/grey_divider.png") repeat-x scroll 0 0 transparent;
    height: 5px;
    margin-top: 0;
}
.button_divider {
    margin: -35px auto 20px;
    width: 145px;
}
.divider {
    background: url("../img/divide.png") repeat-x scroll 0 0 transparent;
    height: 5px;
}
.divider_border {
    margin: 20px 0;
    padding: 0;
    border-bottom: 1px dotted #ddd;
}
.index-3 {
    padding: 0 0 80px;
}
#widget-recent-posts h3,
.desc,
#widget-portfolio h3 {
    text-align: center;
}
.widget_newsletter {
    background: #FFFFFF;
    border: 1px solid #ddd;
    padding-top: 20px;
}
.widget_newsletter h3 {
    margin: 10px 0;
}
.widget_newsletter .widget_newsletter_body {
    padding: 10px 20px 20px;
    text-align: center;
}
.widget_newsletter label {
    display: block;
    margin: 10px 0;
    text-align: left;
}
.widget_newsletter input[type="text"] {
    width: 95%;
}
ul.tweeting {
    margin-left: 0;
    text-align: left;
}
ul.tweeting li {
    list-style-type: none;
    height: 35px;
    overflow: hidden;
    line-height: 2em;
}
ul.tweeting li span {
    margin-left: 10px;
}
#flickr_badge_wrapper img {
    border-color: #555;
    border-style: solid;
    border-width: 2px;
    float: left;
    height: 54px;
    margin: 0 0 7px 7px;
    transition: all 0.3s ease-out 0s;
    width: 54px;
}
.not-found {
    padding-top: 50px;
}
.not-found h1 {
    font-size: 120px;
    padding-bottom: 20px;
}
.not-found-icon {
    display: block;
    font-size: 220px;
    line-height: 1em;
    text-align: center;
}
.footer-widget {
    border-bottom: 1px solid #555;
    margin-bottom: 15px;
    padding: 15px 0;
}
.footer-widget h3 {
    border-bottom: 1px solid #555;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: left;
}
#toggle {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAQAAADYv8WvAAAADklEQVQIHWNgYGBoACMABIoBAUIssgcAAAAASUVORK5CYII=);
    background-color: #222;
    cursor: pointer;
    height: 30px;
    margin: 0 auto;
    padding: 8px 5px 5px 6px;
    position: relative;
    text-align: center;
    top: -20px;
    width: 35px;
    border: 3px solid #222;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    border-radius: 60px;
    -khtml-border-radius: 60px;
}
ul.latest-comments {
    margin-left: 0;
}
ul.latest-comments li {
    text-align: left;
    list-style-type: none;
    height: 35px;
    overflow: hidden;
    line-height: 2em;
}
ul.latest-comments span {
    width: 25px;
    display: inline-block;
}
#widget-logo {
    margin: 10px auto;
    padding: 10px 0;
}
.map-holder {
    margin-bottom: 20px;
}
.map-holder p {
    display: block;
    font-size: 13px;
    margin-top: 10px;
    text-align: center;
}
.map-holder a:hover {
    color: #000;
}
#map {
    width: 100%;
    height: 400px;
}
#map img {
    max-width: none;
}
#map p {
    color: #333;
}
#map a {
    color: #333;
    text-shadow: none;
}
#map .gmap_marker {
    color: #333;
    text-shadow: none;
}
.hero-unit {
    background-color: #F8F8F8;
    border: 1px solid #EFEFEF;
    padding: 20px 0;
    text-align: center;
}
.hero-unit h1 {
    font-size: 30px;
    line-height: 140%;
    text-shadow: 1px 1px 0 #FFFFFF, 2px 2px 0 #AAAAAA;
}
.hero-unit p {
    margin-bottom: 12px;
    margin-top: 3px;
}
.header-title-middle {
    margin: 50px 0 20px;
}
.header-title-middle .button_divider {
    margin: -25px auto 20px;
}
#myCarousel .carousel-caption {
    left: 40px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 6%;
    height: 210px;
    background: transparent;
}
#myCarousel .carousel-control {
    border: medium none;
    border-radius: 0 0 0 0;
    color: #FFFFFF;
    font-weight: 100;
    height: 35px;
    left: 0;
    line-height: 35px;
    opacity: 0.8;
    position: absolute;
    text-align: center;
    top: 48px;
    width: 35px;
    padding: 2px 0;
}
#myCarousel .carousel-control.right {
    left: 0;
    top: 33%;
}
#myCarousel .carousel-caption h1 {
    background: none repeat scroll 0 0 rgba(35, 35, 35, 0.6);
    color: #FFFFFF;
    font-size: 30px;
    padding: 5px 15px;
    text-transform: uppercase;
}
#myCarousel .carousel-caption p {
    color: #fff;
    max-height: 65px;
    overflow: hidden;
    padding: 10px 15px;
    margin-bottom: 10px;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);
}
p.about,
.experts {
    text-align: center;
}
.info>img {
    margin: 25px 0;
    opacity: 0.8;
    width: 150px;
}
.social-icons {
    margin-left: 0;
    margin-top: 20px;
}
.social-icons li {
    display: inline-block;
    list-style: none outside none;
}
.slogan {
    padding: 5px 0;
}
.slogan h3 {
    letter-spacing: 0.1em;
    text-align: center;
    text-shadow: none;
}
.full-slogan h3,
.full-slogan p {
    color: #CCCCCC;
    line-height: 1.2em;
    text-shadow: none;
}
.full-slogan {
    background: none repeat scroll 0 0 #222222;
    margin: 0 auto 30px;
    padding: 30px 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.full-slogan-inner {
    padding: 0 30px;
}
.full-slogan-inner a.button-large {
    float: left;
    font-size: 1.5em;
    margin: 20px 0;
    padding: 10px 20px;
}
.full-slogan-inner a.button-large:hover {
    text-decoration: none;
}
.footer {
    padding-bottom: 16px;
    padding-top: 20px;
    text-align: center;
    background-color: #eee;
    color: #444;
}
.footer p {
    margin-bottom: 0;
}
.parallax {
    width: 100%;
    position: relative;
    overflow: hidden;
    background: #000;
    color: #ffffff;
    z-index: 2;
}
.parallax h1,
.parallax h2,
.parallax h3,
.parallax h4,
.parallax h5,
.parallax h6 {
    color: inherit;
}
.parallax {
    display: table;
    overflow: hidden;
}
.parallax-content {
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    position: relative;
}
.overlay {
    background: url(../img/overlay/05.png);
    width: 100%;
    position: absolute;
    overflow: hidden;
    height: 100%;
    opacity: 0.8;
}
.parallax-bg {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: repeat-y;
    background-position: top center;
}
@media (min-width: 1200px) {
    #myCarousel {
        overflow: hidden;
    }
}
@media (min-width: 979px) and (max-width: 1200px) {
    .taglinedesc {
        font-size: 14px;
        font-style: italic;
        line-height: 10px;
    }
    #home-slogan h1 {
        font-size: 30px;
        font-weight: 300;
        letter-spacing: -1px;
        line-height: 30px;
    }
    #home-slogan h1 .colored {
        font-size: 45px;
        font-weight: 400;
        letter-spacing: -3px;
        line-height: 60px;
    }
}
@media (min-width: 768px) and (max-width: 979px) {
    .header {
        margin-top: 20px;
    }
    #primary-menu {
        float: none;
        margin: 0 auto;
        padding: 0;
        width: 80%;
    }
    #primary-menu ul {
        display: none;
    }
    #primary-menu select {
        display: block;
        outline: none;
    }
}
@media (max-width: 768px) {
    .header,
    .featured-wrapper,
    .homepage_widgets_outer {
        margin-left: -20px;
        margin-right: -20px;
    }
    #logo {
        text-align: center;
    }
    #primary-menu {
        float: none;
        margin: 0 auto;
        padding: 0;
        width: 80%;
    }
    #primary-menu ul {
        display: none;
    }
    #primary-menu select {
        display: block;
        outline: none;
    }
    .breadcrumb {
        float: none;
    }
}
@media (min-width: 480px) and (max-width: 768px) {
    body {
        padding-top: 100px;
    }
    .header,
    .featured-wrapper,
    .homepage_widgets_outer {
        margin-left: -20px;
        margin-right: -20px;
    }
    #logo {
        text-align: center;
    }
    #primary-menu {
        float: none;
        margin: 0 auto;
        padding: 0;
        width: 80%;
    }
    #primary-menu ul {
        display: none;
    }
    #primary-menu select {
        display: block;
        outline: none;
    }
    .sitemap-holder {
        text-align: center;
    }
    .contact-box {
        float: none;
        text-align: center;
    }
    .social-media li,
    .sitemap-menu li,
    .sitemap-menu li a {
        float: none;
        display: inline-block;
        text-align: center;
    }
    .breadcrumb {
        float: none;
    }
    .homepage_widgets_outer {
        padding-left: 10px;
        padding-right: 10px;
    }
    .widget-about,
    .widget-tweet,
    .widget-comments,
    .widget-flickr,
    #widget-recent-posts,
    .widget_newsletter,
    #widget-testimonial {
        margin-bottom: 30px;
    }
    ul#sponsor li {
        margin: 10px;
    }
    .flexslider .slides>li {
        text-align: center;
    }
    .flexslider .slides>li p,
    .flexslider .slides>li h4 {
        margin-top: 10px;
    }
    .flexslider .slides img {
        margin: 0 auto;
    }
    .footer {
        margin-left: -20px;
        margin-right: -20px;
        padding-right: 20px;
        padding-left: 20px;
    }
}
@media (max-width: 480px) {
    .header,
    .featured-wrapper,
    .homepage_widgets_outer {
        margin-left: -20px;
        margin-right: -20px;
    }
    #logo {
        text-align: center;
    }
    #primary-menu {
        float: none;
        margin: 0 auto;
        padding: 0;
        width: 80%;
    }
    #primary-menu ul {
        display: none;
    }
    #primary-menu select {
        display: block;
        outline: none;
    }
    .sitemap-holder {
        text-align: center;
    }
    .contact-box {
        float: none;
        text-align: center;
    }
    .social-media li {
        float: none;
        display: inline-block;
    }
    .breadcrumb {
        float: none;
    }
    .homepage_widgets_outer {
        padding-left: 10px;
        padding-right: 10px;
    }
    .widget-about,
    .widget-tweet,
    .widget-comments,
    .widget-flickr,
    #widget-recent-posts,
    .widget_newsletter,
    #widget-testimonial {
        margin-bottom: 30px;
    }
    ul#sponsor li {
        margin: 10px;
    }
    .flexslider .slides>li {
        text-align: center;
    }
    .flexslider .slides img {
        margin: 0 auto;
    }
}
/*
#site-header {
  position: relative;
  top: -55px;
}*/

#primary-menu a {
    white-space: nowrap;
}
#primary-menu a img {
    display: inline-block;
    height: 2em;
    line-height: 1.5em;
}
.searchform form {
    margin-bottom: 0px;
}
#main {
    padding-top: 30px;
}

ul.services_big > li {
  padding: 0 0 20px 0;
  text-align: left;
  position: relative;
}

.services_big div{
  position: relative;
}

/*.services_big div::before {
  position: absolute;
  content: ' ';
  width: 10px;
  height: 10px;
  top: -7px;
  left: 50%;
  margin-left: -9px;
  transform: rotate(45deg);
}*/

ul.services_big p,
ul.services_big ul  {
  padding: 10px 10px;
}
ul.services_big p {
  font-size: 18px;
  margin-bottom: 0px;
}
.services_big li li {
  padding: 0;
  margin: 0;
}
ul.services_big li h3 {
  text-align: center;
  margin: 0;
  padding: 10px 10px 10px;
    font-size: 26px;
}

ul.services_big img {
  max-width: 208px;
  max-height: 113.23px;
  display: block;
  margin: 0 auto 20px;
}

.is-sticky header {
  z-index: 1000 !important;
  background-color: #fff;
  box-shadow: 0px 4px 10px #999;
}
#logo img {
  transition: all 0.3s ease-in-out;
}

.is-sticky #logo {
  padding: 10px;
  text-align: left;
}

.is-sticky #logo img {
  max-height: 70px
}

.is-sticky #primary-menu>ul {
  margin-top: 10px;
}

.row-fluid .span2_4 {
  width: 17.9487179487%;
}

.row-fluid .span4_8 {
  width: 38.4615384615%;
}

.searchform-mobile {
  display: none;
}

.margin50 {
  margin: 50px 0;
}
.marginTop80 {
  margin-top: 80px;
}
.marginTop60 {
  margin-top: 60px;
}

.padding20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.links ul {
  list-style: none;
  margin-left: 0;
}


.links li {
	margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.links li::after {
  position: absolute;
  bottom: -24px;
  right: -25px;
	display: block;
  content: '';
  background-color: #42A2D6;
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
}

@media (min-width: 500px) {	
	.links ul {
	  display: flex;
	}

	.links li {
	  flex: 1;
	  margin-right: 10px;
	}
	
	.links li:last-child {
		margin-right: 0;
	}
}

.links li a {
  padding: 20px 12px;
  background-color: #eee;
  font-size: 23px;
  display: block;
}

.links li a:hover {
  background-color: #42A2D6;
  color: #fff;
}

.links li p:last-child {
  text-align: left;
  margin-bottom: 0px;
}

.links li img, .links li .webuntis-logo {
  display: inline-block;
  max-height: 18px;
}

.webuntis-logo {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  color: #797979;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -1px;
}

.webuntis-logo-orange {
  color: #f3880c;
}

.logos {
  text-align: left;
}
.logos img {
  margin-bottom: 20px;
}

.termine {
	padding: 0;
	margin: 0 0 1em 0;
	border-top: 1px solid #6CACD9;
    max-height: 400px;
    overflow-y: hidden;
}
.termine:after {
	content: ' ';
	clear: both;
}
.termine li {
	display: block;
	overflow: hidden;
	border-bottom: 1px solid #6CACD9;
	min-height: 5.5em;
	padding: 1.2em 0.5em 0.2em;
}

.termine span.date {
	display: inline;
	float: left;
	padding-right: 1.5em;
}

.termine span.month {
	font-weight: bold;
  letter-spacing: 5px;
}

.termine span.day {
	font-size: 2em;
	font-weight: bold;
	color: #2E7BB2;
}

.termine span.year {
  letter-spacing: 2px;
}

.termine p.where {
	margin: 0 0 0.25em;
}

.termineUp, .termineDown {
    background-color: #42A2D6;
    text-align: center;
    cursor: pointer;
}
.termineUp:hover, .termineUp:focus, .termineDown:hover, .termineDown:focus {
    background-color: #0e90d2;
}
.termineUp.disabled,.termineUp.disabled:hover, .termineUp.disabled:focus, .termineDown.disabled:hover, .termineDown.disabled:focus, .termineDown.disabled {
    background-color: #ffffff;
    cursor: auto;
    display: none;
}

@media (max-width: 980px) {
  .searchform-mobile {
    display: block;
  }
  #site-header {
    top: 0;
  }
  #logo {
    text-align: center;
  }
  .row-fluid .span2_4, .row-fluid .span4_8 {
    width: 100%;
    margin-left: 0;
  }
}

.text-col2 p, .text-col3 p, .text-col3 img {
  margin-bottom: 1.5em;
}

.text-col2, .text-col3 {
  column-gap: 30px;
}

.text-col2 {
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2;
}

.text-col3 {
  -webkit-column-count: 3; /* Chrome, Safari, Opera */
  -moz-column-count: 3; /* Firefox */
  column-count: 3;
}

.schulart_header {
  background-color: #fff;
}

.schulart_header .row-fluid {
  margin-bottom: 15px;
}

.schulart_header, .schulart_bilderstreifen, .schulart_content, .schulart_profile {
  margin-bottom: 20px;
}

.schulart_header .span8 {
  margin-bottom: 0;
}

.schulart_header h1 {
  margin-top: 30px;
  font-size: 3.5em;
}
@media (max-width: 560px) {
  .schulart_header h1 {
    font-size: 2.5em;
  }
}
@media (max-width: 420px) {
  .schulart_header h1 {
    font-size: 1.5em;
  }
}

.schulart_bilderstreifen {
  padding-top: 30px;
}

.feature-bg {
  min-height: 180px;
  position: relative;
  z-index: 5;
}

.table {
  display: table;
}

.tcell-center {
  display: table-cell;
  vertical-align: middle;
}

.schulart-logo img {
  float: right;
  margin-top: 30px;
  max-height: 150px;
}

.schulart_bilderstreifen .span12 {
  height: 220px;
  background-size: cover;
  background-position: top center;
}

.schulart_profile {
  margin-top: 20px;
}

ul.schularten_list {
  list-style: none;
  margin-left: 0px;
}
.schularten_list li:nth-child(2n) {
  background-color: #f0f0f0;
}
.schularten_list li {
  padding-left: 15px;
  padding-top: 10px;
}

.jobs iframe {
  margin-left: auto;
  margin-right: auto;
}
.section-bottom-20 section {
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.schulart_profile .profil {
  margin-bottom: 25px
}

.schulart_profile .profil header {
  padding: 10px;
}

.schulart_profile .profil header h3 {
  margin: 0;
  font-size: 22px;
}

.schulart_profile .profil > div {
  padding: 5px 10px
}

.pflichtfachE .row-fluid {
  height: 100%;
}

.pflichtfachE .row-fluid .bg-highlight {
  height: 100%;
  border-left: 2px dotted #ccc;
  padding-left: 10px;
}

@media (min-width: 768px) {

  .schulart_profile .profil.wg {
    width: 43.41880341880342%;
  }

  .schulart_profile .profil.wgi, .schulart_profile .profil.wgf {
    width: 25.52649572649573%;
  }
}

section.flags {
  margin-bottom: 60px;
}

.flags .span2_4 {
  text-align: center;
}

.flags .flag-icon {
  font-size: 90px;
}

section.partner {
  margin-top: 40px;
}

.marginBottom20 {
  margin-bottom: 20px !important
}

.marginBottom40 {
  margin-bottom: 40px !important
}

.marginBottom60 {
  margin-bottom: 60px !important
}

.partnerschaft_header .table {
  margin-bottom: 0px;
}

.zeitungsschnipsel {
  position: relative;
  background-position: top left;
  background-size: contain;
  background-repeat: no-repeat;
}

section.unterricht tr.pause {
  font-size: 0.5em;
}

section.unterricht tr.pause td {
  padding: 0;
}

.leitbild img {
  display: block;
  max-width: 100%;
  max-height: 400px;
  margin-left: auto;
  margin-right: auto;
  transition: all 500ms ease-in-out;
}

.leitbild .span5, .leitbild .span2 {
  min-height: 400px;
}

.leitbild .image {
  position: relative;
}

.leitbild .image img{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}

.leitbild .image:hover img {
    max-width: 100vw;
    max-height: 800px;
    z-index: 5;
}

.us_verwaltung .qualitaet {
  min-height: 400px;
}

.qualitaet {
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
    padding: 20px;
}
.qualitaet h2{
  padding-top: 34%;
  text-shadow: 0 0 5px #fff;
}

.schulleben_page .qualitaet h2 {
    padding-top: 48%;
}

.rundgang {
	position: relative;
	width: 100%;
	padding-bottom: 58.33333333333333%;
	background-color: #f0f0f0;
}

.rundgang .flexslider {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	max-height: 100%;
	max-width: 100%;
}
.rundgang .flexslider  img {
	width: 100%;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	
  animation: greyfade ease-in-out 8s;
  animation-iteration-count: infinite;
  -webkit-animation: greyfade ease-in-out 8s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: greyfade ease-in-out 8s;
  -moz-animation-iteration-count: infinite;
  -o-animation: greyfade ease-in-out 8s;
  -o-animation-iteration-count: infinite;
  -ms-animation: greyfade ease-in-out 8s;
  -ms-animation-iteration-count: infinite;
	
}
.rundgang  .inner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 30px;
  padding: 20% 20px 20%;
  text-align: center;
  text-shadow: 0 0 5px #fff;
}

@keyframes greyfade{
  0% {
	filter: grayscale(100%);
  }
  33% {
	filter: grayscale(100%);
  }
66% {
	filter: grayscale(0%);
}
  100% {
	filter: grayscale(0%);
  }
}

@-moz-keyframes greyfade{
  0% {
	filter: grayscale(100%);
  }
  33% {
	filter: grayscale(100%);
  }
  66% {
  	filter: grayscale(0%);
  }
  100% {
	filter: grayscale(0%);
  }
}

@-webkit-keyframes greyfade {
  0% {
	-webkit-filter: grayscale(100%);
  }
  33% {
	-webkit-filter: grayscale(100%);
  }
  66% {
  	-webkit-filter: grayscale(0%);
  }
  100% {
	-webkit-filter: grayscale(0%);
  }
}

@-o-keyframes greyfade {
  0% {
	filter: grayscale(100%);
  }
  33% {
	filter: grayscale(100%);
  }
  66% {
  	filter: grayscale(0%);
  }
  100% {
	filter: grayscale(0%);
  }
}

@-ms-keyframes greyfade {
  0% {
	filter: grayscale(100%);
  }
  33% {
	filter: grayscale(100%);
  }
  66% {
  	filter: grayscale(0%);
  }
  100% {
	filter: grayscale(0%);
  }
}

.splash h2 {
  font-size: 3em;
  color: white;
  text-shadow: 0 0 8px #333333;
}

.splash {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.splash .anmeldung {
  text-align: center;
  min-height: 400px;
}
.splash .anmeldung h2{
  padding-top: 150px;
  color: #42A2D6;
  text-shadow: 0 0 5px #fff;
}

.anmeldezeiten {
  display: block;
  background-color: rgba(240, 240, 240, 0.5);
  min-height: 400px;
  margin-top: 100px;
  padding: 20px 20px 20px 20px;
}

img.featured {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.no-margin .span6 {
  width: 50%;
  margin-left: 0%;
}

.no-margin .span3 {
  width: 25%;
  margin-left: 0;
}

.no-margin .span2 {
  width: 15.95%;
  margin-left: 0.75%;
}
.no-margin .span5 {
  width: 40.95%;
  margin-left: 0.75%;
}

@media (max-width: 770px) {
  .no-margin .span6, .no-margin .span2, .no-margin .span3, .no-margin .span5 {
    width: 100%;
    margin-left: 0;
  }

  .text-col2 {
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1; /* Firefox */
    column-count: 1;
  }

  .text-col3 {
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1; /* Firefox */
    column-count: 1;
  }
  header#site-header, .schulart_header {
    position: static !important;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
	.span3.filterable-item {
		width: 48.93617021276595%;
		margin-left: 2.127659574468085%;
		float: left;
	}
	.span3.filterable-item:nth-child(2n+3) {
		margin-left: 0;
	}
}

p.lehrer {
  text-align: right;
}

.fullwidth {
  padding-left: 20px;
  padding-right: 20px;
}

.latest-comments i {
  display: inline-block;
  min-width: 1.5em;
}

.spread-img img {
  width: 100%;
}

.graybg {
  background-color: #f7f7f7;
}

.pp_sozial {
	display: none;
}

.schulart_bilderstreifen {
    border-bottom: 2px solid #42A2D6;
    padding-bottom: 1em;
    margin-bottom: 1.5em;
}

.schulart_content .span4 > img:first-child {
    margin-top: 0.4em;
}

.schulart-logo {
    float: right !important;
}

.schulart_header .span9 {
    margin-left: 0;
}

@media (max-width: 767px) {
    .schulart-logo {
        float: left !important;
    }
}